#mini-card {
  /* background: rgb(37,168,0);
background: linear-gradient(315deg, rgba(37,168,0,1) 0%, rgba(233,233,233,1) 30%); */

border-top-width: 3px;
border-right-width: 3px;
border-bottom-width: 3px;
border-left-width: 3px;
min-width: 260px;
min-height: 165px;
}

#group-text {
  position: relative;
  top: -10px;
  padding-top: 5px;
  font-size: 10px;
}