#OffCanvas{
  max-width: 375px;
}
/* @media screen and (max-width: 2650px){
  #MRTHolder {
    max-width: 2000px;
  }
}  */
.rct-options{
  width: 25px;
}