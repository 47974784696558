#StackedChartContainer {
  background-color: white;
  padding-top: 15px;
}
#SiteTreeSV {
  min-width: 450px;
}
#MainStackRow {
  min-height: 850px;
}
#StackedAreaChart {
  min-height: 750px;
}
  