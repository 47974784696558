.accordion-button {
  padding: 1px 0px 1px 3px !important;
  border-style: none !important;
}
.accordion-button .collapsed {
  padding: 1px 0px 1px 3px !important;
  border-style: none !important;
}
.form-check {
  padding-top: 2px !important;
  padding-left: 28px !important;
}
