#gm-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#main-gm-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#my-groups-card {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    height: 400px;
}
#shared-group-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  height: 405px;
}
#edit-group-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  height: 825px;
  min-width: 320px;
  width: 100%;
}

#userGroups {
  max-height: 250px;
  overflow-y: scroll;
}
#otherUserGroups {
  max-height: 250px;
  overflow-y: scroll;
}
#switch_table {
  width: 50%;
  margin-left: 15px;
}

#Static-Search-Row-GM {
  height: 680px;
}
#available-col{
  max-height: 625px;
  overflow-y: scroll;
}
#assigned-col{
  max-height: 625px;
  overflow-y: scroll;
}

/* #user-groups-thead {
  position: sticky;
  border: solid 2px #dee2e6;
  top: 0;
  background-color: #4c4c4d; 
  color: white; 
} */


