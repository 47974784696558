.card-header {
  box-shadow: 0px 1px 0px #000000;
  font-weight: bolder;
}
/* .card-style {
  box-shadow: 5px 5px 5px #888888;
} */
.inner-card-style {
  box-shadow: 0px 2px 2px #888888;
  border: 1px solid #888888;
}

#left-col {
  padding: 0;
}
#right-col {
  padding: 0;
} 
#middle-col {
  padding: 0;
}
#fav-item{
  /* text-shadow: 0 0 2px #000000; */
  font-family: sans-serif;
  box-shadow:
      /* inset 0 0 2px 1px #000000, */
      0px 2px 4px 0 #888888;
  border-radius: 10px;

}
#portal-news-text {
  box-shadow: inset 0px 0px 2px #888888;
  border-radius: 5px;
  padding: 5px;
  height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.home-row {
  background: rgb(0,47,101);
  background: linear-gradient(90deg, rgba(0,47,101,1) 0%, rgba(233,233,233,1) 100%);
  box-shadow: 5px 1px 5px #888888;
}
#page-title {
  font-weight: bold;
  text-shadow: 1px 2px #000000;
}