/* body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
} */

.titleFirst {
  font-family: pramsFontBold;
}

.titleLast {
  font-family: pramsFontRegular;
}

.form-signIn {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signIn .checkbox {
  font-weight: 400;
}

.form-signIn .form-floating:focus-within {
  z-index: 2;
}

.form-signIn input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signIn input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.help-modal-button {
  margin-top: 15px;
}

.li-help {
  list-style: none;
}
.gotoLogo{
  width: 25px;
  height: 25px;
  margin-top: 15px;
  margin-left: 15px;
}
