#top-nav-row {
  background-color: var(--pramsBlue);
  height: 60px;
  width: 100%;
  margin: 0;
  box-shadow: inset 0px -1px 15px #000000;

}
#offCanvas-topNav .offcanvas-body, 
#offCanvas-topNav .offcanvas-header {
  box-shadow: 25px 0px 10px rgba(0, 0, 0, 0.2);
}
.offcanvas-header {
  font-weight: bold;
  color: white;
  background-color: rgb(13, 106, 244);
}
#menu-header {
  margin-top: 5px;
  margin-left: 10px;
  font-weight: bold;
}
#menu-subheader {
  margin-top: 5px;
  margin-left: 10px;
  font-weight: bold;
}
#menu-subheader-dot {
  height: 15px;
  width: 15px;
}