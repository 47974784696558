
body {
  background-color: #E9E9E9 !important;
  overflow-x: hidden;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

::-webkit-scrollbar
{
  width: 7px;
  height: 7px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	background-image: 
  -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(0,47,101)),
    color-stop(0.72, rgb(73,125,189)),
    color-stop(0.86, rgb(0,47,101)));
  }

  @media screen and (max-width: 1440px){
    #containerInApp{
      max-width: 1400px;
    }
  }
  @media screen and (max-width: 1024px){
    #containerInApp{
      max-width: 985px;
    }
  }
  @media screen and (max-width: 768px){
    #containerInApp{
      max-width: 728px;
    }
  }
  
  /* .homeCardCont {
    background-color: #E9E9E9;
    height: 100vh;
  
  } */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* Got this from : */
/* https://codepen.io/devstreak/pen/dMYgeO */

/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
} */

