#today-oil, #today-gas, #today-water {
  font-weight: 700;
  font-size: 55px;
}

#card-button-settings {
  padding: 0;
  height: 15px;
  position: relative;
  top: 9px;
  left: 220px;
  z-index: 1 ;

}
#icon-button {
  margin-top: -13px;
}
#Dyn-Card-Sites {
  width: 100%;
  height: 300px;
  padding: 0;
  margin-left: 15px;
}
#Dyn-Card-Site-List{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}
#bottom-buttons {
  margin-top: 1px;
  margin-left: 10px;
}